<template>
	<v-layout align-center fill-height scroll-y>
		<v-container>
			<v-img class="ma-auto" src="/img/welybug.gif" max-width="250px" />
			<v-flex v-t="'error_page.title'" display-4 />
			<v-flex v-t="'error_page.text'" headline />
		</v-container>
	</v-layout>
</template>

<script>
export default {
	name: 'UnknownSubDomain'
}
</script>
